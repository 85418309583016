<template>
  <main>
    <div
      class="bg-header"
      :class="{ 'short-bg-header': true, 'top-webview' : checkWebview(), 'padding-bg-regis': isHasCaution}"
    ></div>

    <div
      class="container home-content"
      :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}"
    >
      <h2 class="gift-code-heading">ギフト受け取り完了</h2>

      <div class="gift-code">
        <p class="gift-code-point" v-if="giftCodeDetail.point">
          {{ giftCodeDetail.point | numberWithCommas(',', '') }} ptを獲得しました。
        </p>

        <router-link
          :to="{name: 'Profile.Mypage'}"
          class="btn"
        >
          マイページへ
          <ChevronRightIcon size="24"/>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ChevronRightIcon } from 'vue-feather-icons'
import GiftCode from '@/mixins/giftCode.mixin'
import Common from '@/mixins/common.mixin'

export default {
  name: 'Index',

  mixins: [Common, GiftCode],

  components: {
    ChevronRightIcon
  },

  data () {
    return {
      detail: null
    }
  },

  computed: {
    ...mapGetters('giftCode', ['giftCodeDetail']),

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  methods: {
    ...mapActions('giftCode', ['getDetailGiftCode'])
  },

  async created () {
    if (this.isLoggedIn) {
      await this.userPoint()
    }

    const giftCodeInputId = this.$route.params.id

    await this.getDetailGiftCode([giftCodeInputId])
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/_giftcode.scss';

.gift-code {
  &-point {
    margin-bottom: 150px;
  }
}
</style>
